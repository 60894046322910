/* Definitions */
@font-face {
  font-family: 'Inter';
  src: url(../public/fonts/Inter-VariableFont_slntwght.woff2) format('woff2');
  /* src: url(./fonts/GemunuLibre-VariableFont_wght.ttf); */
}

:root {
  --health: #e85be8;
  --environment: #fc4b38;
  --infrastructure: #5080ea;
}

::selection {
  background: black;
  color: white;
}

.container {
  height: 100%;
  width: 100%;
}

.min-height-zero {
  min-height: 0;
  flex: 1;
}

/* site border */
/* #border {
    position: fixed;
    width: calc(100vw - 1px);
    height: calc(100vh - 1px);
    border: 2px solid black;
    z-index: 99;
} */

/* Utility */
.standard-padding {
  padding: 1em;
}

.thicker-padding {
  padding: 2rem;
}

.col-gap {
  column-gap: 1rem;
}

.small-col-gap {
  column-gap: 0.5rem;
}

.big-col-gap {
  column-gap: 1.5rem;
}

.row-gap {
  row-gap: 1rem;
}

.big-row-gap {
  row-gap: 1.5rem;
}

.small-text {
  font-size: 1.75vh;
}

.underline {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.hover-underline:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 1px;
}

.bold {
  font-weight: bold;
}

.block-border {
  border-block: 2px solid black;
}

.no-top-border {
  border-top: none !important;
}

.no-left-border {
  border-left: none !important;
}

.no-right-border {
  border-right: none !important;
}

.no-bottom-border {
  border-bottom: none !important;
}

.top-border {
  border-top: 2px solid black !important;
}

.bottom-border {
  border-bottom: 2px solid black !important;
}

.bottom-border-small {
  border-bottom: 1px solid black;
}

.no-pointer {
  pointer-events: none;
}

.height-transition {
  transition: height 1s, max-height 1s;
}

.full-height {
  height: 100%;
  transition: height 1s;
}

.no-height {
  height: 0;
  transition: height 1s;
}

.half-height-transition {
  transition: height 0.5s;
}

.quarter-height {
  height: calc(100vh / 4);
}

.slide {
  transition: all 500ms;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 9px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
  /* border-radius: 7px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(127, 255, 0); */
  background: white;
  outline: 2px solid black;
}

.community-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: black;
}

.community-dropdown::-webkit-scrollbar-track {
  background-color: white;
  border: 2px solid black;
  border-top: none;
  border-bottom: none;
  border-right: 1px solid white;
}

/* Document */

html {
  padding: 0;
  margin: 0;
  height: 100%;
}

body {
  font-family: 'Inter', sans-serif !important;
  overflow: hidden;
  width: 100vw;
  padding: 0;
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 500;
}

h6 {
  margin-bottom: 0rem !important;
}

small {
  height: fit-content;
}

.white-link {
  color: white;
  cursor: pointer;
}

.white-link:hover {
  color: gray;
}

/* List */
ol,
ul {
  padding-left: 1rem !important;
}

/* table */
td {
  padding: 0.25rem 0.5rem !important;
}

/* Links */
a:link {
  color: inherit;
}

a:visited {
  color: inherit;
}

a:hover {
  color: gray !important;
}

a:active {
  color: gray !important;
}

.Black {
  color: black;
}

.Health {
  color: #e85be8;
}

.Environment {
  color: #fc4b38;
}

.Mobility {
  color: #5080ea;
}

.links-Health a {
  color: var(--health);
}

.links-Environment a {
  color: var(--environment);
}

.links-Mobility a {
  color: var(--infrastructure);
}

/* Navigation Bar */
.nav-chapters {
  flex-grow: 1;
  padding: 1em 1.5em;
  transition: flex 1s, background-color 0.5s, color 0.5s,
    border-bottom-color 0.5s;
  border: 2px solid black;
  border-top: none;
  min-height: 0;
  background-color: white;
  /* cursor: pointer; */
}

.nav-chapters:hover {
  background-color: black;
  color: white;
}

.nav-chapters:hover,
.nav-chapters-active {
  background-color: black;
  color: white;
  /* border-bottom: 2px solid white; */
}

.nav-chapters-active {
  height: 106px;
}

.nav-chapters-active h6 {
  color: white;
  font-size: 2.5rem;
  /* border-bottom: 2px solid white; */
}

.expanded-nav {
  background-color: black;
  color: white;
  flex-grow: 2 !important;
  padding: 1em 1.5em !important;
  /* border-bottom-color: white; */
  overflow: auto;
}

.collapsed-nav {
  height: fit-content;
  flex-grow: 0;
}

.collapsed-nav:hover {
  cursor: pointer;
}

.nav-chapters-content {
  max-height: 0;
  opacity: 0;
}

.nav-chapters-content-expanded {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: calc(100vh - 2.75rem - 3vw - 3rem);
  opacity: 1;
  transition: max-height 1s, opacity 1s;
}

.transition-font {
  transition: font-size 1s;
  white-space: pre-line;
  cursor: pointer;
}

.nav-title {
  opacity: 1;
  transition: opacity 0.75s;
}

.collapse-nav-title {
  margin: 0;
  padding: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}

.collapse-text {
  font-size: 1.25rem;
  margin: 0;
}

/* About */
.about-collapsed {
  flex-grow: 0;
  padding: 0.5em 1em;
}

.about-button {
  background: white;
  color: black;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  padding: 0.25em 1.5em;
  border-radius: 5px;
  pointer-events: auto;
  border: 2px solid black;
  transition: background-color 0.5s, color 0.5s, border 0.5s;
}

.about-button:hover {
  color: white;
  background-color: black;
  border: 2px solid white;
}

/* Dropdown */
.dropdown-bar {
  padding: 0.25rem 0.5rem;
  column-gap: 0.25rem;
}

.dropdown-bar:hover {
  cursor: pointer;
}

.dropdown-bar-black {
  border: 2px solid black;
}

.dropdown-bar-Health {
  border: 2px solid #e85be8;
  background-color: #e85be8;
}

.dropdown-bar-Environment {
  border: 2px solid #fc4b38;
  background-color: #fc4b38;
}

.dropdown-bar-Mobility {
  border: 2px solid #5080ea;
  background-color: #5080ea;
}

.dropdown-body {
  width: 100%;
  height: fit-content;
  overflow-y: auto;
  border: 2px solid black;
  border-top: none;
  background-color: white;
  z-index: 3;
  overflow-x: hidden;
  transition: max-height 0.5s;
}

.short-dropdown {
  max-height: 20vh;
}

.long-dropdown {
  max-height: 50vh;
}

.dropdown-item {
  background-color: white !important;
  /* border: 2px solid black !important; */
  border-right: none;
  border-top: none;
  border-bottom: none !important;
  padding-right: 1em !important;
  border-left: none !important;
  min-width: 0 !important;
  display: block !important;
  width: 100% !important;
  cursor: pointer;
}

.break-text {
  word-wrap: break-word !important;
}

.dropdown-item:hover,
.dropdown-item-active,
.grid-item:hover,
.grid-item-active {
  background-color: black !important;
  color: white !important;
  cursor: pointer;
}

.community-search-container {
  height: 2em;
  font-size: 1rem;
}

.community-nav-content {
  overflow: auto;
}

/*.community-nav-container {
    overflow: auto;
}*/

.community-nav-text {
  bottom: 0;
  width: 100%;
}

.community-description {
  padding: 1rem 0 0rem;
  color: white;
}

.add-community-btn {
  background-color: black;
  border: 2px solid white;
  width: 100%;
  padding: 0.25em 0.5em;
  height: 2em;
}

.remove-community-btn {
  color: white;
  z-index: 1;
  right: 0;
  cursor: pointer;
}

.community-search {
  height: 100%;
  border-radius: 0 !important;
  outline: none;
  /* font-style: italic; */
  font-weight: 500;
  font-size: 0.8rem;
  background-color: black;
  color: white;
  border: 1.5px solid white;
  padding: 0.25em 0.5em;
}

.community-search::-webkit-search-cancel-button {
  appearance: none;
  -webkit-appearance: none;
}

.community-search-active {
  background-color: black;
  color: white;
}

.community-dropdown {
  position: absolute;
  height: fit-content;
  max-height: 25vh;
  overflow-y: auto;
  border-top: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  z-index: 2;
  padding: 0;
  width: 100% !important;
  padding: 0 !important;
}

.strong-placeholder::placeholder {
  color: white;
  font-size: 0.8rem;
}

.strong-placeholder:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}

.strong-placeholder:hover::placeholder {
  color: black;
}

.community-search-container > .fa-plus:hover {
  cursor: pointer;
}

.community-search-container > .fa-plus:hover ~ .strong-placeholder {
  background-color: white;
}

.community-search-container
  > .fa-plus:hover
  ~ .strong-placeholder::placeholder {
  color: black;
}

.shorter {
  max-height: calc(100vh / 7);
}

.longer {
  max-height: calc(100vh / 3);
}

.search-item {
  font-size: 11px;
  border: 2px solid white;
  border-top: none;
}

.search-item-inactive {
  background-color: white;
  color: black;
}

.search-item:hover,
.search-item-active {
  background-color: black;
  color: white;
}

.valid-search-container {
  position: absolute;
  height: 100%;
  aspect-ratio: 1;
  color: white;
  right: 1rem;
}

::placeholder {
  padding: 4px;
  font-size: 0.75em;
  color: #bbbbbb;
  font-style: normal;
}

/* chapter 1 */
.backsplash-image {
  width: 100%;
  max-width: 100%;
  height: 100vh;
  object-fit: cover;
  cursor: default;
  filter: brightness(0.5);
}

/* Middle Column */
.middle-column {
  background-color: white;
  z-index: 3;
  border-right: 2px solid black;
  border-bottom: 2px solid black;
  /* border: none; */
  /* border-left: none; */
  opacity: 1;
}

.middle-transition {
  transition: width 0.5s ease-in, opacity 0.5s;
}

.middle-transition-community {
  transition: width 0.5s 0.6s, opacity 0.6s 0.6s;
}

.collapsed-middle-column {
  width: 0;
  overflow: hidden;
  border-right: none;
  opacity: 0;
}

/* Boundary Toggle */
.boundary-toggle {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.5rem;
}

.boundary-toggle-item-active {
  background-color: white;
  color: black;
  cursor: default;
}

.boundary-toggle-item-inactive {
  background-color: black;
  color: white;
}

.boundary-toggle-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding: 0.25rem;
  align-items: center;
  height: 100%;
  border: 2px solid white;
  transition: background-color 0.5s, color 0.5s;
}

.boundary-toggle-item:hover {
  background-color: white;
  color: black;
}

/* .boundary-toggle-item:focus~.community-search-container {
  background-color: white;
  color: black;
} */

/* .boundary-toggle-item:hover {
  background-color: black;
  color: white;
  transition: background-color 0.5s, color 0.5s;
} */

/* Issue Tiles */
.issues-tile-container {
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.issues-tile-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  height: 2em;
}

.issues-grid > * {
  padding: 1.5rem;
}

.floating-share {
  /* position: fixed; */
  /* bottom: 0; */
  /* right: 0; */
  /* margin: 2rem; */
  z-index: 3;
  display: normal;
  width: auto;
  height: auto;
  padding: 0;
}

.issues-tile-body {
  /* padding: 1rem; */
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 4em);
  padding-bottom: 0;
  border-left: none;
}

.issues-tile-text-container {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding: 1.5em 1.5em 2.5em 1.5em;
  height: 100%;
  overflow: auto !important;
  border: 2rem solid;
}

.issues-tile-ranking {
  width: 100%;
}

.issues-tile-ranking-invis {
  max-height: 0;
  opacity: 0;
  pointer-events: none;
  transition: max-height 1s, opacity 0.5s;
}

.issues-tile-ranking-vis {
  max-height: 500vh;
  opacity: 1;
  transition: max-height 1s, opacity 0.5s 0.5s;
}

.ranking-button {
  /* margin-top: 0.25rem; */
  font-size: 1.5rem;
  cursor: pointer;
}

.issue-tile-viz {
  width: 50%;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
}

.py-3 {
  padding-bottom: 0 !important;
}

/* Issues Column */
.issues-chapters {
  flex-grow: 1;
  word-wrap: normal;
  transition: background-color 0.5s, color 0.5s, flex 1s,
    border-bottom-color 0.5s;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.issues-chapters > p {
  padding-left: 1.5rem;
}

.issues-chapters > div > *:first-child {
  padding: 1rem 0;
  padding-left: 1.5rem;
  /* border: 2px solid red; */
}

.issues-chapters-inactive {
  background-color: white;
  color: black;
}

.issues-chapters:hover,
.issues-chapters-active {
  background-color: black;
  color: white;
}

.issues-chapters:hover > * {
  color: white !important;
}

.accordion-body {
  height: 0;
  font-size: 0;
  opacity: 0;
  pointer-events: none;
  transition: flex 1s, font-size 1s, height 1s;
  padding: 0 !important;
}

.expand-demo {
  height: 30vh;
  padding: 1rem;
}

.collapse-demo {
  height: 0vh;
  padding: 0;
}

.invis {
  max-height: 0;
  opacity: 0;
  font-size: 0;
  pointer-events: none;
  visibility: hidden;
  transition: font-size 1s, opacity 1s, max-height 1s;
  margin-bottom: 0;
}

.vis {
  height: fit-content;
  max-height: 100vh;
  opacity: 1;
  font-size: 1rem;
  transition: font-size 0.3s, opacity 0.8s 0.3s, max-height 1s ease-out;
}

.collapse-issue {
  flex-grow: 0 !important;
  height: fit-content;
  border-bottom: 2px solid black;
}

.expand-issue {
  flex-grow: 2;
  font-size: 1rem;
  opacity: 1;
  margin: 0rem !important;
  padding: 1.5rem 1.5rem !important;
  pointer-events: all;
  overflow-y: scroll;
}

.expand-issue::-webkit-scrollbar {
  /* display: none; */
  width: 8px;
}

.issues-arrow {
  opacity: 0;
  transition: opacity 0.25s;
}

.issues-arrow-row {
  cursor: pointer;
}

.issues-arrow-row:hover .issues-arrow,
.issues-arrow-active {
  opacity: 1;
}

.issue-container {
  /* padding: 1em; */
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  /* border: 1px solid black; */
  height: 100%;
}

.issue-container .issue-writeup {
  /* padding: 2.5em; */
  padding-top: 0;
}

.sticky-basic {
  position: sticky;
  top: 3.25em;
  z-index: 3;
  background-color: white;
}

/* Community Column */
.community-profile-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow: auto;
  background-color: white;
  scroll-behavior: smooth;
}

.community-profile-container header {
  font-size: 20px;
  padding: 1.5em;
  padding-left: 1rem;
  padding-bottom: 10px !important;
}

/* Right Column */
#main-content {
  /* border: 2px solid black; */
  border-left: none;
}

/* Map */

.wiper {
  height: 100vh;
  width: 0.5rem;
  background-color: deeppink;
  z-index: 3;
  cursor: ew-resize;
}

.map-container {
  height: 100vh;
  position: absolute;
  right: 0;
}

.map-subcontainer {
  display: flex;
  right: 0;
}

.individual-maps {
  border: 1px solid black;
  position: relative;
  height: 100vh;
}

/* wiper v2 */
.splitter-container {
  position: absolute;
  bottom: 3rem;
  left: 10%;
  width: 80%;
  z-index: 9;
}

.splitter {
  width: 100%;
}

#deckgl-wrapper {
  border-block: 1px solid black;
  /*    background-color: black;
    border-block: 2px solid black;
    left: 50% !important;
    width: 50% !important;
    height: 100% !important;*/
}

.map-loader-container {
  position: fixed;
  z-index: 5;
  width: inherit;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: hsla(0, 0%, 0%, 50%);
  color: white;
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #fff;
  animation: loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  z-index: 10;
}

.loader div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}

.loader div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}

.loader div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes loader {
  0% {
    top: 8px;
    height: 64px;
  }

  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.map-pinned {
  position: absolute;
  width: max-content;
  transition: 0.5s ease-in-out;
  cursor: crosshair;
  transition: all 100ms ease-in-out;
  /* transition: 'all 100ms ease-in-out'; */
}

.map-pinned-close-button {
  position: absolute;
  background-color: transparent;
  border: none;

  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.map-tooltip {
  background-color: white !important;
}

.map-tooltip > *:not(:last-child) {
  border: 2px solid black;
  border-bottom: none;
}

.map-tooltip > *:last-child {
  border: 2px solid black;
  border-top: none;
}

.map-tooltip > *:not(:first-child) {
  width: 250px;
}

.map-tooltip-header {
  padding: 0.25rem 0.5rem;
  background-color: black;
  color: white;
  font-size: 0.8rem;
}

.tooltip-grid {
  display: grid;
  grid-template-columns: auto 1fr 4fr;
  grid-gap: 2px;
  justify-items: left;
}

.map-tooltip-info {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.75em;
  background: white;
}

.map-tooltip-info:last-child {
  padding-top: 0px;
}

.map-tooltip-text {
  color: white;
}

.map-tooltip-ranking {
  font-weight: bold;
  color: black !important;
  text-decoration: none !important;
}

/* Map Toggle */
#toggle-container {
  z-index: 3;
}

.show-map {
  z-index: 2;
}

.hide-map {
  z-index: -1;
  /*visibility: hidden;*/
}

/* map zoom buttons */
.map-zoom-buttons-container {
  position: absolute;
  display: grid;
  grid-template-rows: auto auto;
  place-content: center center;
  text-align: center;
  z-index: 10;
  gap: 0.5rem;
}

.map-zoom-buttons-container > * {
  font-size: 2rem;
  padding: 0.3rem;
  color: black;
  background-color: white;
  transition: 0.25s ease-in-out;
  outline: 2px solid black;
}

.map-zoom-buttons-container > :hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.map-zoom-toggle {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 1rem;
  z-index: 3;
  display: normal;
  width: auto;
  height: auto;
  padding: 0;
}

.map-toggle-container {
  display: flex;
  cursor: pointer;
  overflow: hidden;
  gap: 2px;
  border: 2px solid black;
}

.map-toggle-container * {
  transition: opacity 0.25s ease-in-out !important;
}

.map-source-info-container {
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
}

.map-source-info-container p {
  color: white;
  font-size: 0.6rem;
  padding: 0.25rem 0.5rem;
  margin: 0;
  background-color: hsla(0, 0%, 34%, 50%);
  user-select: none;
}

.disabled * {
  opacity: 0;
  pointer-events: none;
}

.map-toggle {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: color 0.5s, background-color 0.5s;
  background-color: white;
  font-size: 1.33rem;
  width: 44px;
  height: 44px;
  z-index: 5;

  border: none;
  border-bottom: 0px solid black;
}

.url-copied {
  bottom: 3rem;
  font-size: 0.75rem;
  border: 2px solid black;
  color: white;
  padding: 0.5em;
  width: fit-content;
  pointer-events: none;
  right: 0;
}

.toggle-tooltip {
  font-size: 0.75rem;
  color: white;
  padding: 0.25em 0.5em;
  width: fit-content;
  pointer-events: none;
}

.map-notable-container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  position: absolute;
  z-index: 3;
  bottom: 1rem;
  left: 1rem;
  width: auto;
}

.map-notable-indicators > * {
  background: white;
  padding: 0.25em 0.5em;
  transition: color 0.5s, background-color 0.5s;
  font-size: 0.8rem;
  cursor: pointer;
}

.map-notable-indicators > * {
  border: 2px solid black;
  border-top: 0px;
}

/* Histogram */
.cancelButtonText {
  cursor: pointer;
  opacity: 0.6;
}

.cancelButtonText:hover {
  opacity: 1;
  text-decoration: underline;
}

#resetButton {
  cursor: pointer;
  opacity: 0.6;
}

#resetButton:hover {
  opacity: 1;
  text-decoration: underline;
}

.pinnedTextUp {
  cursor: pointer;
  /* opacity: 0.6; */
}

.pinnedTextUp:hover {
  /* opacity: 1; */
  text-decoration: underline;
}

.goToButton {
  cursor: pointer;
}

.pinnedTextUp:hover {
  text-decoration: underline;
}

/* Share Button */
/* .share {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 0.5rem;
    cursor: pointer;
    background-color: white;
    border: 1px solid black;
    padding: 0 0 0 0.75rem;
    transition: 0.5s ease-in-out;
}

.share:hover {
    background-color: black;
    border: 1px solid white;
}

.share * {
    color: black;
    transition: 0.5s ease-in-out;
}

.share:hover * {
    color: white;
} */

.share-icon {
  width: 2rem;
}

.share-icon-container {
  display: flex;
  align-items: center;
}

.share-icons {
  z-index: 6;
  display: flex;
  flex-direction: row;
  column-gap: 0.75rem;
  cursor: pointer;
}

.share-icons a {
  font-size: 1.25rem;
  font-weight: 500;
  transition: 0.125s ease-in-out;
}

.share-icons a:hover {
  transform: scale(1.2);
}

.close-icon {
  cursor: 'pointer';
  font-size: 1rem;
}

.close-icon:hover {
  transform: scale(1.2);
  cursor: pointer;
}

.toggle-share-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 1.5rem;
  z-index: 3;
}

.share-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  row-gap: 1.5rem;
  padding: 0 0.5rem;
  transition: 0.5s ease-in-out;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

/* Issue Cards */
.issues-card-container {
  display: flex;
  cursor: pointer;
  /* border-bottom: 2px solid black; */
  position: relative;
  margin-top: 20px;
}

.issues-card-container-wrapper {
  position: relative;
}

.issues-card-container:hover .issues-chapters-inactive:not(.expand-toggle) {
  background-color: black;
  color: white;
}

.issues-card-header {
  /* background-color: black; */
  /* color: white; */
  /* padding: 0.25em 1.5rem; */
  /* padding-right: 0; */
  justify-content: space-between;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto auto;
}

.issues-card-body {
  /* padding: 0.5em 0.75em; */
  background-color: white;
  /* border: 2px solid black; */
}

.test * {
  border: none !important;
}

.issues-card-title-container {
  align-items: baseline;
  display: grid;
  grid-template-columns: auto 1fr;
}

.issues-card-button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.x-mark * {
  width: 1.25rem;
  /* height: 100%; */
  font-size: 2rem;
  padding: 0 1rem;
}

.x-mark:hover {
  transform: scale(1.1);
}

/* Issue Tags */
.issue-tags-container {
  position: sticky;
  bottom: 0;
  padding: 0;
  background-color: hsla(0, 0%, 100%, 0.8);
  border-top: 1px solid black;
}

.issue-tags {
  display: flex;
  padding: 1.5em;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
}

.issues-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.inactive-tag {
  color: white;
  background-color: black;
}

.inactive-tag:hover {
  background-color: white;
  color: black;
}

.active-tag {
  background-color: white;
  color: black;
}

.active-tag:hover {
  background-color: white;
  color: black;
}

.inactive-tag.issues-tag {
  color: black;
  background-color: white;
}

.inactive-tag.issues-tag:hover {
  background-color: black;
  color: white;
}

.active-tag.issues-tag {
  background-color: black;
  color: white;
}

.active-tag.issues-tag:hover {
  background-color: white;
  color: black;
}

.map-toggle:hover {
  background-color: white;
  color: black;
}

.svg-inline--fa {
  height: 0.8em;
}

.fa-caret-right:hover {
  cursor: pointer;
}

.fa-caret-down:hover {
  cursor: pointer;
}

/* Modal */
.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-card {
  width: 80vw;
  height: 80vh;
}

/* Misc */

.info-tooltip {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 0.25rem 0.5rem;
  z-index: 10;
  /* width: 10rem; */
  z-index: 1000;
}

.placeholder-legend {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  column-gap: 0em;
  align-items: center;
}

.placeholder-legend-ethnicity {
  grid-template-columns: repeat(5, 1fr);
  column-gap: 0.25em;
}

.legend-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.legend-scale {
  /* border: 2px solid black; */
  height: 3vh;
  width: 100%;
}

.small-font {
  font-size: 0.8em;
  font-weight: 500;
}

.small-font-vh {
  font-size: 1.5vh;
  font-weight: 500;
}

.rankings-container {
  display: flex;
  justify-content: space-between;
}

.download-ranks {
  background-color: transparent;
  width: 10%;
  font-size: 2.5vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border: none;
}

.download-ranks:hover {
  transform: scale(1.1);
}

.readable-font {
  font-size: 16px;
}

.ellipses {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.smaller-font {
  font-size: 0.6em;
}

.add-demographic-btn {
  background-color: white;
  border: 1.5px solid white;
  width: 100%;
  padding: 0.25rem;
  color: black;
  font-weight: bold;
}

.expand-demographic {
  /*height: max(8rem, calc((100vh - 14.75rem) / 3));*/
  flex-grow: 1;
  opacity: 1;
  transition: height 1s, opacity 1s 0.5s;
}

.collapse-demographic {
  height: 0;
  opacity: 0;
  pointer-events: none;
  transition: height 1s 0.5s, opacity 1s;
}

.thirds {
  max-height: calc((100vh - 14.75rem) / 3);
}

.transition-flex {
  transition: flex-grow 1s;
}

.transition-opacity {
  transition: opacity 0.5s 0.5s;
}

.transition-color {
  transition: background-color 0.5s;
}

.ranking-chart {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
  overflow: auto;
}

.carousel-inner {
  padding-bottom: calc(2rem + 10px);
}

.carousel-indicators [data-bs-target] {
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
  opacity: 1 !important;
  border: 1px solid white !important;
  transition-timing-function: ease-in;
}

.carousel-indicators .active {
  background-color: #000 !important;
}

#demographic-slider {
  position: relative !important;
}

#demographic-slider .carousel-indicators [data-bs-target] {
  border-radius: 50%;
  height: 10px !important;
  width: 10px !important;
  opacity: 1 !important;
  border: 1.5px solid black !important;
  transition-timing-function: ease-in;
}

#demographic-slider .carousel-indicators .active {
  background-color: #000000 !important;
}

.about-chapters {
  flex-grow: 1;
  border: 1px solid black;
  padding: 1em;
  transition: color 0.5s, background-color 0.5s;
}

.about-chapters:hover,
.about-chapters-active {
  background-color: black;
  color: white;
}

.indent {
  padding-left: 1.5rem;
}

.solution-dropdown {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rotate-right {
  transform: rotate(90deg);
  transition: transform 0.5s;
}

.rotate-left {
  transform: rotate(-90deg);
  transition: transform 0.5s;
}

.transform-transition {
  transition: transform 0.5s;
}

.table {
  border-spacing: 0 !important;
  margin-bottom: 0 !important;
}

.table > thead {
  background-color: white;
  color: black;
  border-color: black;
}

.table > tbody {
  border: 1px solid black;
}

.form-check-input:checked {
  background-color: black !important;
  border-color: black !important;
  border-color: black !important;
}

.form-check-input[type='checkbox'] {
  border-radius: 0 !important;
}

.form-check-input:focus {
  border: 1px solid black !important;
  box-shadow: 0 0 0 !important;
}

.typewriter-container {
  font-size: 3rem;
  font-weight: 500;
  color: white;
  pointer-events: none;
}

.mobile-what-is {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  height: 100%;
}

.mobile-what-is-description {
  background-color: white;
  padding: 2rem 2rem 5rem 2rem;
}

.mobile-citywide,
.mobile-community {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  /* border: 2px solid blue; */
  overflow: hidden;
}

.mobile-community-search-screen {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100vw;
  height: 100%;
  overflow: hidden;
}

.mobile-community-search-screen {
  pointer-events: none;
}

.mobile-community-search-screen .typewriter-container {
  pointer-events: none;
}

.mobile-community-search-screen .boundary-toggle {
  border: 1px solid black;
  border-top: 1px solid white;
  margin-bottom: 0 !important;
}

.mobile-community-search-screen .boundary-toggle-item {
  border: 1px solid black;
}

.mobile-community-search-screen .community-search {
  color: black;
}

.mobile-community-search-screen .search-item {
  border: 1px solid black;
  width: calc(100vw - 2rem);
}

.mobile-community-search-screen .community-dropdown {
  width: calc(100vw - 2rem - 2px);
  border: 1px solid black;
  border-top: none;
}

.mobile-community-search-screen .community-search-container {
  margin-top: 0 !important;
  height: 5vh;
}

.borderRight .community-search-container {
  border-right: 1px solid black;
}

.noRightBorder .community-search-container {
  border-right: none;
}

.mobile-community-search-screen .search-item {
  width: 100%;
  border: none;
  border-bottom: solid 1px black;
}

.mobile-community-search-screen .community-search {
  height: calc(5vh - 1px);
}

.Typewriter__wrapper {
  text-decoration-line: underline !important;
}

.transparent-bg {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.white-bg {
  background-color: white;
}

.prompt {
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(50%, -50%);
  width: 30%;
}

.some-height {
  max-height: 100vh;
  font-size: 1.25rem;
  opacity: 1;
  padding: 0.5rem;
}

.no-height {
  max-height: 0;
  font-size: 0;
  opacity: 0;
}

.transition-height {
  transition: height 0.5s, max-height 0.5s, font-size 0.5s, opacity 0.5s;
}

.transition-color {
  transition: color 0.5s;
}

.transition-opacity {
  transition: opacity 0.5s;
}

.chart-footer:hover {
  text-decoration: underline;
}

/* The switch - the box around the slider */
.switch {
  display: inline-block;
  min-width: 40px;
  height: 24px;
  position: relative;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #ffffff;
  transition: 0.4s;
  border: 2px solid black;
}

.active-slider {
  background-color: black;
  transition: background-color 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  top: -1px;
  left: -2px;
  background-color: white;
  transition: 0.4s;
  border: 2px solid black;
}

input:checked + .slider:before {
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider-demo-toggle {
  position: absolute;
  right: 0;
  top: 0;
  background-color: white;
}

.transit-toggle {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
}

.transit-toggle * {
  margin: 0 !important;
}

.transit-toggle *:not(:last-child) {
  padding: 0 0.5rem 0 0;
}

.transit-toggle form div {
  padding: 0 !important;
  margin: 0 !important;
}

.transit-toggle .form-check-label {
  padding-left: 0.5rem;
}

#assistive-touch-div {
  /*top:0;
    left: 0;*/
  transition: transform 0.5s, left 0s, top 0s, height 0.5s 0.2s, width 0.5s 0.2s,
    background-color 1s, color 1s;
  position: absolute;
}

.assistive-touch {
  padding: 7px;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  justify-content: space-between;
  margin: 0 !important;
}

.assistive-touch-grow {
  background-color: white;
  color: black;
  width: 100vw;
  height: 100vh;
  border-radius: 0px;
  border: 2px solid black;
}

.assistive-touch-shrink {
  background-color: black;
  color: white;
  border-radius: 10px;
  height: 56px;
  width: 56px;
  border: 2px solid white;
}

.assistive-touch-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 10px;
  font-weight: 700;
  line-height: 12.1px;
}

.menu-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #146fd1;
}

.big-button {
  width: 100%;
  padding: 0.25rem 0.5rem;
  transition: color 1s, background-color, border 1s;
  display: flex;
  flex-direction: row;
  column-gap: 0.25rem;
  align-items: center;
  justify-content: space-between;
}

.big-button-active {
  color: white;
  background-color: black;
  border: 2px solid white;
}

.big-button-inactive {
  color: black;
  background-color: white;
  border: 2px solid black;
}

#about-container {
  /* padding: 1.25rem; */
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-padding: 5rem;
}

.spacer {
  height: 65vh;
}

.scroll-body {
  width: calc(50vw);
  /* position: relative; */
  right: 0;
  z-index: 1;
  min-height: 100vh;
}

.scroll-body-desktop {
  padding: 6em 2.5em 1.25em 2.5em;
}

.scroll-body-desktop > h1 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.about-mobile-padding > *:not(.table) {
  padding: 0 2.5rem;
}

.scroll-title {
  width: calc(50vw);
  /* width: 50%; */
  padding: 2.5rem 2.5rem;
  height: 6em;
  position: absolute;
  left: 50vw;
  /* display:inline-block; */
  /* background-color: linear-gradient(180deg, white 80%, transparent); */
  /* background-color: red; */
  background-image: linear-gradient(180deg, white 70%, transparent);
  z-index: 1;
}

.scroll-title-content {
  /* top: 2px; */
  left: 50vw;
  position: absolute;
  /* background-color: white; */
  /* width: calc(50%-5px); */
}

.scroll-content {
  position: inherit;
  scroll-snap-align: start;
  overflow-x: auto;
}

.scroll-title-box {
  width: 50vw;
  position: absolute;
  left: 50vw;
  z-index: 1;
  padding: 1em 1.5em;
}

.about-link {
  text-decoration-line: none;
}

.about-link:hover {
  color: white !important;
}

.menu-item {
  cursor: pointer;
}

.scroll-menu {
  position: absolute;
  left: 25vw;
  top: 5rem;
  bottom: 3.75rem;
  width: 25%;
  padding: 0 1.25rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  /* border: 2px red; */
}

.scroll-menu-box {
  position: absolute;
  width: 25%;
  height: 100vh;
  /* left: 25vw;
    width: 25%;
    flex-direction: column;
    justify-content: space-between;
    color: black; */
  /* border: 2px red !important; */
}

.border-box {
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  /* z-index: 5; */
}

.scroll-menu-chapters {
  flex-grow: 1;
  padding: 1rem 1.5em;
  transition: flex 1s, background-color 0.5s, color 0.5s,
    border-bottom-color 0.5s;
  border: 2px solid white;
  border-top: none;
  min-height: 0;
  cursor: pointer;
  font-size: 2vh;
}

.scroll-menu-chapters:hover {
  background-color: black;
  color: white;
  border-bottom: 2px solid black;
}

.scroll-menu-chapters:hover,
.nav-chapters-active {
  background-color: black;
  color: white;
  border-bottom: 2px solid black;
  /* border-top: 2px solid black; */
}

.scroll-right-box {
  width: calc(50vw);
  position: relative;
  left: 25vw;
  /* z-index: ; */
  /* border-left: 2px #000; */
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video {
  width: 100%;
  height: 100%;
}

.mobile-video {
  width: 100%;
  transition: all 0.5s;
}

.issues-profile-community-jump:hover {
  text-decoration-line: underline;
  cursor: pointer;
}

/*TODO: mobile CSS*/

.mobile-nav {
  width: 100%;
  transition: height 1s;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.mobile-menu {
  top: 1rem;
  right: 1rem;
  height: calc(100% - 2rem);
}

.mobile-nav-header {
  z-index: 2;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  position: relative;
  transition: height 0.5s, padding 0.5s, border 0.5s;
  display: grid;
  grid-template-columns: 1fr auto auto;
}

.mobile-nav-header:first-child {
  padding: 0.5rem 0rem 0.5rem 0.5rem;
}

#mobile-landing {
  position: relative;
  background-color: white;
  z-index: 2;
  height: 100%;
  /*transition: flex-grow.5s, height 0.5s;*/
  /*display: flex;
    flex-direction: column;*/
}

.mobile-landing-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  color: white;
  position: absolute;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.7);
}

.mobile-landing-video {
  height: 60%;
  position: relative;
}

.mobile-landing-menu {
  border: 1px solid black;
  transition: height 0.5s;
  background-color: white;
  min-height: 0;
}

.mobile-landing-menu-item {
  flex: 0;
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  transition: height 0.5s, padding 0.5s, border-bottom-width 0.5s;
}

.mobile-nav-chapter {
  background-color: red;
  cursor: pointer;
  transition: flex-grow 0.5s, padding 0.25s 0.25s, background-color 0.5s,
    color 0.5s;
}

.mobile-citywide-chapter {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  cursor: pointer;
  transition: flex-grow 0.5s, padding 0.5s 0.5s, background-color 0.5s,
    color 0.5s, height 0.6s;
  background-color: white;
}

.mobile-citywide-content {
  transition: flex-grow 1s, padding 1s 0.5s;
  overflow: scroll;
}

.mobile-boundary-toggle {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  cursor: pointer;
}

.mobile-boundary-item {
  padding: 0.5rem;
  border: 1.5px solid black;
  border-bottom-width: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.demographics-container {
  min-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding-bottom: 1.5rem; */
}

.mobile-demographics-toggle {
  border-top: 2px solid black;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.5s, color 0.5s;
  padding: 0.25rem 1rem;
  /* height: 7vh; */
  z-index: 1;
  position: relative;
}

.mobile-demographics-container {
  flex-grow: 2;
  overflow: auto;
  max-height: 40vh;
  min-height: 0;
  background-color: white;
  z-index: 1;
  position: relative;
  transition: height 0.5s, padding 0.5s;
}

.citywide-nav {
  height: 10vh;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  z-index: 1;
}

.citywide-non-map-content > *:not(.mobile-histogram) {
  padding: 1rem;
}

.category-dropdown {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  column-gap: 0.5rem;
}

.issue-dropdown {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0rem 1rem;
  border: 1px solid black;
  border-top: none;
  border-bottom: none;
  column-gap: 0.5rem;
  border-left: none;
}

.link-underline > span:hover {
  content: '';
  cursor: pointer;
  font-weight: 500 !important;
}

.link-underline > span:hover::before {
  content: '→';
  cursor: pointer;
  font-weight: 500 !important;
}

.citywide-issue-dropdown {
  min-width: 0;
}

.citywide-categories-dropdown {
  min-width: 0;
}

.mobile-dropdown-item {
  padding: 0.25rem 1rem;
  border: 1px solid black;
}

.mobile-issues-profile-container {
  pointer-events: none;
  transition: height 0.5s;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

/* Restore pointer events for all sub-elements */
.mobile-issues-profile-container > * {
  pointer-events: auto;
}

.mobile-citywide-nav {
  height: 5vh;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: white;
}

.mobile-citywide-nav-dropdown {
  height: 5vh;
  border-bottom: 2px solid black;
  border-top: 2px solid black;
  padding: 0.25rem 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: 3vw;
  z-index: 1;
  background-color: white;
}

.mobile-citywide-nav-dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  position: relative;
  min-width: 0;
  overflow: hidden;
}

.mobile-citywide-nav-dropdown-item-grow {
  height: 5vh;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 0.25rem 1rem;
  opacity: 1;
  transition: height 0.5s, padding 0.4s, opacity 0.5ms;
}

.mobile-citywide-nav-dropdown-item-shrink {
  height: 0;
  border-right: none;
  border-bottom: none;
  padding: 0rem 1rem;
  opacity: 0;
  transition: height 0.5s, padding 0.4s, opacity 0.5ms 0.3s;
}

.mobile-citywide-nav-text {
  width: 100%;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 3vw;
}

.attributions {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0.1rem;
}

.attributions a:hover {
  transform: scale(1.1);
  transition: 0.5s ease-in-out;
}

.mobile-boundary-nav {
  height: 7vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  align-items: center;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 576px) {
  .map-notable-container {
    position: absolute;
    z-index: 3;
    bottom: 10%;
    left: 0rem;
    width: 100vw;
    display: block;
    background-color: black;
  }

  .map-notable-indicators {
    display: grid;
    grid-template-columns: auto auto auto;
    background-color: black;
    gap: 1px;
    border: 1px solid black;
  }

  .map-notable-indicators > * {
    padding: 0.5rem;
    height: 100%;
    width: 100%;
    border: none !important;
    text-align: center;
  }

  .big-button {
    padding: 1rem;
  }

  .typewriter-container > * {
    /* padding: 1rem 0; */
    line-height: 4rem;
  }

  .typewriter-container > * > * {
    padding: 0.5rem;
    color: black;
  }

  ::-webkit-scrollbar {
    /* width: 8px; */
    display: none;
  }

  .dropdown-body {
    width: calc(100% - 2rem - 2px);
  }

  .map-zoom-toggle {
    top: 10rem;
    bottom: auto;
  }

  .share-button-container {
    position: relative;
    flex-direction: column-reverse;
    border: none;
    gap: 0.5rem;
  }

  .share-icons {
    /* position: absolute; */
    top: 100%;
    width: 100%;
    /* outline: 2px solid black; */
    /* flex-direction: column; */
    align-items: center;
    gap: 1.5rem;
    /* background-color: white; */
    padding: 0.1rem;
  }

  .share-icons a svg {
    height: 1.25rem;
    width: 1.25rem;
  }

  .issues-tile-text-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1.5em 0;
    height: auto;
  }

  .carousel-indicators [data-bs-target] {
    border-radius: 50%;
    height: 10px !important;
    width: 10px !important;
    opacity: 1 !important;
    border: 1px solid black !important;
    transition-timing-function: ease-in;
  }

  .community-dropdown {
    z-index: 50;
  }

  #about-container {
    background-color: white;
    position: relative;
    font-size: small;
    height: max-content;
    display: flex;
    flex-direction: column;
  }

  #about-container::-webkit-scrollbar {
    /* Don't show scrollbar on mobile */
    display: none;
  }

  .scroll-menu-box {
    /* Align underneath title box */
    position: fixed;
    top: calc(70px + 4rem);
    height: calc(100% - 70px - 4rem);
    width: 33%;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 2px solid black;
    z-index: 2;
    display: flex;
    justify-content: space-between;

    /* Allow some readability underneath menu */
    background-color: rgba(255, 255, 255, 0.9);
    /* backdrop-filter: blur(1px); */

    /* Animate the sliding in and out */
    transition: right 300ms ease-in-out;
  }

  .scroll-menu-box .scroll-menu-chapters {
    padding: 15px;
    border-color: transparent;
  }

  .scroll-menu-box h6 {
    font-size: 12px;
  }

  /* Move right box to the left side on mobile */
  .scroll-right-box {
    left: auto;
    width: auto;
  }

  .scroll-title-box {
    /* Keep title at top */
    position: sticky;
    top: 0;
    left: auto;
    width: 100%;
    height: 70px;
  }

  .scroll-title-box h6 {
    /* Do not extend the full screen width */
    width: 75%;
    height: 70px;
  }

  .scroll-body {
    width: 100%;
  }

  .scroll-content {
    width: 100%;
  }

  /* min 16px font size prevents autozoom on ios */
  #community-search {
    font-size: 16px;
  }

  /* MOBILE END */
}

.community-legend {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.regular-border {
  border: 1px solid black;
}

.no-border {
  border: none;
}

.active-scheme {
  background-color: black;
  color: white;
}

.inactive-scheme {
  background-color: white;
  color: black;
}

.active-scheme-black-bg {
  background-color: white;
  color: black;
}

.inactive-scheme-black-bg {
  background-color: black;
  color: white;
}

.shrink {
  flex-grow: 0;
}

.grow {
  flex-grow: 2;
}

.big-padding {
  padding: 1rem;
}

.small-padding {
  padding: 0.5rem 1rem;
}

.no-padding {
  padding: 0rem !important;
}

.big-text {
  font-size: calc(1.375rem + 1.5vw);
}

.smaller-text {
  font-size: 0.75rem;
  font-weight: 500;
}

.no-text {
  font-size: 0;
}

.full-width {
  max-width: 100%;
}

.half-width {
  max-width: 50%;
}

.hide-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mobile-transition-font {
  transition: font-size 0.25s 0.25s, opacity 0.45s;
}

.issues-profile-table-row {
  transition: all 0.5s;
}

.issues-profile-table-row:hover {
  background-color: black;
  color: white;
}

.about-table-row {
  transition: all 0.5s;
}

.about-table-row:hover {
  background-color: black;
  color: white;
}

.about-table-row a {
  text-decoration: none;
}

.about-table-row a:hover {
  color: white !important;
  text-decoration: underline;
}

.collapse-map-button {
  position: absolute;
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  align-items: center;
  justify-content: center;
  height: 6vh;
  background-color: white;
  top: 45.1vh;
  left: 0vw;
  transition: 0.5s ease-in-out;
  /* border-top-right-radius: 10%; */
  /* border-bottom-right-radius: 10%; */
  z-index: 5;
  cursor: pointer;
}

.collapse-map-button:hover {
  background-color: black;
  color: white;
}

.collapse-map-tooltip {
  position: absolute;
  left: 4vh;
  font-size: 0.75rem;
  font-weight: 500;
  background-color: white;
  border: 2px solid black;
  color: black;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  /* border-radius: 8px; */
  width: fit-content;
  pointer-events: none;
}

.map-solutions-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  align-items: center;
  justify-content: center;

  background-color: white;
  top: 45.1vh;
  right: 0;
  transition: 0.25s ease-in-out;
  /* border-top-right-radius: 10%; */
  /* border-bottom-right-radius: 10%; */
  z-index: 5;
}

.map-solutions-button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
}

.map-solutions-button h4 {
  writing-mode: vertical-lr;
}

.map-solutions {
  position: absolute;
  z-index: 50;
  background-color: white;
  top: 0;
  bottom: 0;

  transition: all 0.5s ease;
}

.histogram-responsive-box {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: auto;
  overflow-x: hidden;
}

.histogram-responsive-box > * {
  cursor: pointer;
}

/*ANIMATED HAMBURGER MENU*/
.toggle-menu {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0 1rem;
  pointer-events: initial;
  position: relative;
  vertical-align: middle;
  z-index: 5;
  height: 100%;
  width: 4rem;
}

.toggle-menu-span-active::before,
.toggle-menu-span-active::after {
  transition: top 0.2s ease-out, transform 0.2s 0.2s ease-out;
  background-color: white;
}

.toggle-menu-span-active::before {
  top: 0;
  transform: rotate3d(0, 0, 1, -45deg);
}

.toggle-menu-span-active::after {
  top: 0;
  transform: rotate3d(0, 0, 1, 45deg);
}

.toggle-menu-span {
  background-color: white;
  content: '';
  display: block;
  height: 2px;
  left: calc(50% - 13px);
  position: absolute;
  top: calc(50% - 1px);
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 24px;
}

.toggle-menu-span-active {
  background-color: transparent;
  transition: background 0.2s ease-out;
}

.toggle-menu-span::before,
.toggle-menu-span::after {
  background-color: white;
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  transform-origin: 50% 50%;
  transition: background-color 0.2s ease-in-out, top 0.2s 0.2s ease-out,
    transform 0.2s linear;
  width: 24px;
}

.toggle-menu-span::before {
  top: 7px;
}

.toggle-menu-span::after {
  top: -7px;
}

.toggle-menu-span-active::before {
  top: 0px;
}

.toggle-menu-span-active::after {
  top: 0px;
}

.mobile-issue-dropdown {
  transition: height 0.4s;
  border: none !important;
  /*
    border-right: 1px solid black !important;
    */
}

.mobile-issue-dropdown-shrink {
  max-height: 0;
}

.mobile-issue-dropdown-grow {
  max-height: 20vh;
}

.mobile-issue-dropdown-item {
  transition: padding 0.2s 0.1s, font-size 0.2s 0.1s;
}

.mobile-map {
  overflow: hidden;
  position: fixed;
}

.mobile-map-toggle {
  height: 100%;
  width: 5vh;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  transition: background-color 0.5s;
}

.mobile-community-search-screen {
  transition: height 0.5s, padding 0.4s;
}

.mobile-community-search-screen .sticky-basic {
  display: none;
}

.issue-tile-image {
  object-fit: cover;
  width: 100%;
}

.issues-card-container-wrapper .solutions-button {
  position: relative;
  bottom: 0;
}

.solutions-button {
  all: unset;
  display: flex;
  background-color: white;
  color: black;
  border: 2px solid black;
  align-self: flex-end;
  /* position: relative; */
  right: 0;
}

.solutions-button > span {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  writing-mode: vertical-lr;
  transition: all 0.25s ease;
}

.solutions-button h4 {
  margin: 0;
}

.solutions-button:hover {
  background-color: white;
  color: black;
}

.expand-rankings {
  background: none;
  width: 100%;
  border-bottom: 0.25rem solid black;
  outline: none;
  text-align: left;
  font-size: 1.5vh !important;
  padding-bottom: 0.5rem;
  display: flex;
}

.rankings-buttons {
  justify-content: flex-start;
  display: flex;
  gap: 0.5em;
}

#city-wide-footer:hover > *,
#city-wide-footer:hover > h6 {
  background-color: black !important;
  color: white;
}

#map-legend p {
  margin: 0;
}

#flooding-legend > span {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 0.5rem;
}

#flooding-legend .color-block {
  flex-shrink: 0;
  width: 67px;
  height: 27px;

  margin-right: 1rem;
}

/*TODO: print preview*/

@media print {
}
